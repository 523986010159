import React from "react"
import PropTypes from "prop-types"

import Layout from "../../components/Layout/Layout"
import GlobalContext from "../../context/GlobalContext"
import { setDataLayer } from "../../helpers/dataLayerSetter"
import { API_PRODUCT_SHAPE } from "../../constants/shapes.constants"

import componentMaker from "../../helpers/ComponentMaker/componentMaker"
import ProductDetailHero from "../../components/UI/ProductDetailHero/ProductDetailHero"
import ProductIngredients from "../../components/UI/IngredientsBlock/ProductIngredients"
import { makePerex } from "../../helpers/DataConvertor.helper"
import SchemaSnippet from "../../components/SchemaSnippet/SchemaSnippet"
import { getTransparentImg } from "../../helpers/ComponentMaker/apiData.helper"
import { gTagScriptConsent } from "../../components/Scripts/Gtm.Head"

const ProductDetail = ({ path, pageContext }) => {
    const { apiData, globalProps, seoData, pageType, dataLayer, globalLabels, brandTheme } = pageContext
    const { Product_Id, Product_Title, Product_Image, Product_Details } = apiData || {}
    const { Food_Service_Description, Ingredients, Nutrition_Facts, Nutrition_Url } = Product_Details || {}

    const pageData = pageContext.pageProps?.contentfulProductDetail
    const finalImage = getTransparentImg(Product_Image) || null
    const finalNutritionImage = getTransparentImg(Nutrition_Url) || null

    const finalSeoData = seoData || {}
    finalSeoData.title = Product_Title
    finalSeoData.metaDescription = makePerex(Food_Service_Description, 200)
    finalSeoData.featuredImage = finalImage ? { url: finalImage } : null
    finalSeoData.ogUrl = path
    finalSeoData.sku = Product_Id
    finalSeoData.category = apiData?.Brand

    setDataLayer(dataLayer, finalSeoData, pageType)

    return (
        <GlobalContext labels={globalLabels} brandTheme={brandTheme}>
            <Layout path={path} seoData={finalSeoData} globalProps={globalProps}>
                <ProductDetailHero
                    productId={Product_Id}
                    title={Product_Title}
                    description={Food_Service_Description}
                    leftImage={finalImage}
                    shareUrl={path}
                />

                <ProductIngredients
                    nutrition={Nutrition_Facts}
                    nutritionImage={finalNutritionImage}
                    ingredients={Ingredients}
                    image={pageData?.image}
                    cta={pageData?.cta}
                    quote={pageData?.quote}
                />

                <SchemaSnippet
                    snippet={`
                {
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": "${Product_Title}",
                    "image": "${finalImage}",
                    "description": "${Food_Service_Description}",
                    "brand": {
                      "@type": "Brand",
                      "name": "${apiData?.Brand}"
                    },
                    "sku": "${Product_Id}"
                }
                `}
                />

                <div className="print:hidden">
                    {componentMaker(pageContext.pageProps?.staticResult?.contentComponents, apiData)}
                </div>
            </Layout>
        </GlobalContext>
    )
}

ProductDetail.propTypes = {
    path: PropTypes.string,
    pageContext: PropTypes.shape({
        pageProps: PropTypes.object,
        apiData: PropTypes.shape(API_PRODUCT_SHAPE),
        globalProps: PropTypes.object,
        seoData: PropTypes.object,
        pageType: PropTypes.string,
        dataLayer: PropTypes.object,
        globalLabels: PropTypes.object,
        brandTheme: PropTypes.shape({
            themeName: PropTypes.string,
        }),
    }),
}

/**
 * Adding to Layout ?
 */
export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}

    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            <script
                async
                type="text/javascript"
                dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
            />
        </>
    )
}

export default ProductDetail
